import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import ScrollProgressRead from "react-scroll-progress-read"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import AuthorInfo from "../../components/author-info/author-info"

import ServicesList from "../../components/services-list/services-list"
import BlogList from "../../components/blog-list/blog-list"

import "../../styles/blog.scss"

const AdministratorPage = () => {
  const imageArticle = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "rudraksha.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Rudrakashas – Astrologer Aravind | Ashtamangalaprasnam Blog"
        description="For All Round Benefits and Absolution from Sins"
        keyword="rudrakasha, astrology, eka, dwi, tri, chatur, pancha, shan, sapta, ashta, nava, dasha, eka daska, dwadasha, trayodasha, chaturdasha, faced, mukhi, sin, attainment"
        image="../../images/rudraksha.jpg"
      />
      <div className="scroll-progress">
        <ScrollProgressRead
          backgroundColor="#CCC"
          barColor="#931a1a"
          height="5px"
          target="article"
        />
      </div>
      <section id="article" className="article">
        <section className="article-header">
          <Image
            className="article-header__image"
            fluid={imageArticle.file.childImageSharp.fluid}
            alt="Rudrakashas held in palm"
          />
          <div className="article-header__text-wrap article-header__text-wrap_orange">
            <h1 className="article__heading">Rudrakashas</h1>
            <p className="article__description">
              For all-round benefits and absolution from sins.
            </p>
          </div>
        </section>
        <section className="container article-wrap">
          <article className="article__content">
            <p>
              The Rudraksha is of Sanskrit origin and comprises of 'Rudra' a
              synonym of 'Lord Shiva' and 'Aksha' synonym of the 'Eye’, which is
              considered to be the most potent manifestation of the Cosmic
              Force. Hence, Rudraksha is the object of veneration and also the
              source to reach the higher self. Rudraksha is often thought to
              represent the connection between the worldly concern and the
              paradise. These beads are the seeds of the Rudraksha fruit
              obtained from Rudraksha trees. The Rudraksha tree is botanically
              known as Elaeocarpus ganitrus and its common name is Utrasum bead
              tree.
            </p>

            <p>
              From ancient times, the power of Holy Rudraksha beads has been
              scripted in various religious texts like ShivmahaPurana,
              Shreemaddevibhagwat, Padma Purana, Ling Purana etc.
            </p>

            <p>
              For thousands of years Rudraksha beads have been worn by mankind
              for good health, religious attainment through Japa and Shakti
              (power) and for fearless life. Saints and sages roaming in the
              forests have lived healthy, fearless and a full life by wearing
              Rudraksha and its Mala. There is no saint, God incarnate, who can
              be identified without these vibrant Rudraksha beads or Mala. Since
              ages, Rudraksha has been tested and worn by the Yogis and Saints
              and they found these beads to be very useful for excellent health
              and spiritual attainments. The power of Rudraksha is felt quickly
              and effectively with the persons who are devoted to religion, have
              strong belief in God, surrender to Vedic lifestyle and do regular
              Japa Practices. The common mass can produce better outcomes if
              they tend to take over the Vedic lifestyle.
            </p>

            <p>The important types of rudrakshas are listed below.</p>

            <div className="article-list-item">
              <h2 className="h4">Eka Mukhi (One faced)</h2>
              <p>
                This is ruled by Lord Shiva himself. It serves to enlighten
                consciousness, destroy sins, past karma and thereby leads to
                Moksha. It will develop divine attitude and all-round happiness.
                The rarest of the Rudrakshas and is held in high regard. Those
                who possess this will get all riches, happiness and prosperity
                and get their desires are met easily. It will develop divine
                attitude and all-round happiness. The natural features of Naga
                Sarpa, Trishula, Shivlinga and omkaram are found on this
                Rudraksha.
              </p>
              <p>
                This serves to enlighten the Super Consciousness, provides
                improved concentration and mental structure changes specific to
                renunciation from worldly affairs. The wearer gets to enjoy all
                comforts at his command but still remains unattached. Eye
                diseases, migraines, depression, OCD, anxiety, heart diseases,
                lung diseases, various psychotic and neurotic disorders etc. are
                said to be cured with this.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Shiva
              </p>
              <p>
                <strong>Ruling planet: </strong>Sun
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Dwi Mukhi (Two Faced)</h2>
              <p>
                This Rudraksha is ruled by Ardhnareeshwar and it blesses the
                wearer with Unity and creates an aura of oneness around him. It
                promises wealth, happiness and inner bliss. It has great
                mystical power and is ideal for those who are looking for a
                soulmate to enter into matrimony. Dwi Mukhi Rudraksha works like
                a mediator to eliminate enmity and if couples were to wear it,
                it is said to enhance their bonds and prevent any breakup. It is
                apt to be given as a wedding gift to the marrying couples. It
                also helps the wearer develop cordial relations with family
                members, friends, co-workers and relatives.
              </p>
              <p>
                The wearer will have control over own desires, emotional
                stability and will develop divinely outlook- very useful in
                awakening kundalini - essentially in varshikarna. Rhinitis,
                infertility and diseases affecting stomach, intestines, kidneys
                will be cured by this.
              </p>
              <p>
                <strong>Ruling God: </strong>Ardhnareeshwar
              </p>
              <p>
                <strong>Ruling planet: </strong>Moon
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Tri Mukhi (Three faced)</h2>
              <p>
                This Rudraksha is ruled by Lord Agni and it cleanses the sins
                and offenses of the wearer and liberates him to move on the path
                of success. Wearing it gives the power and the benefit of
                worshipping the Thrimoorthis (Lord Brahma, Lord Vishnu and Lord
                Shiva). When worn with Thali the lady will be blessed with good
                luck and long lasting, harmonious marital life. It helps to get
                rid of Pitrudosha and will be blessed with children.
              </p>
              <p>
                Help in higher education, improves eyesight and cures ailments
                related to the liver, digestive system, etc. Ideal for those who
                suffer from inferior complexes, subjective fear, guilt and
                depression.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Agni
              </p>
              <p>
                <strong>Ruling planet: </strong>Mars
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Chatur Mukhi (Four Faced)</h2>
              <p>
                This Rudraksha is ruled by Brihaspati the Guru of Devas. The
                wearer of this Rudraksha is blessed with powerful memory,
                knowledge, and wisdom and dispels darkness of ignorance and
                intellectual dullness. This is apt for the students and very
                beneficial for artists, students, scholars, journalists,
                researchers and scientists, writers, etc. It increases
                creativity, memory power, wit and intelligence. This Rudraksha
                is found to be very useful for dowsing (Sathya vani or Bhavishya
                vani).
              </p>
              <p>
                This cures diseases affecting thyroid gland and the respiratory
                system and cures epilepsy, asthma, bronchitis, hay fever etc.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Brahma
              </p>
              <p>
                <strong>Ruling planet: </strong>Mercury, the ailments related to
                Budhadasha can be cured by this.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Pancha Mukhi (Five Faced)</h2>
              <p>
                This is ruled by Lord Rudra Kalaagni. Pancha Mukhi Rudraksha
                helps wearer wake his higher self (Upa Guru), thereby blesses
                him with spiritual faith, wisdom, and insight and instills self-
                awareness and self- worth. Also, it helps with learning and
                developing one’s own uniqueness. This is widely worn by students
                and sages.
              </p>
              <p>
                This is used commonly in Japa mala especially by Ayyappa and the
                Anjaneya devotees and is apt for the ones deciding to go the
                hermit way (this is kalagni swaroopa and rudragana). The wearer
                gains health and peace. Pancha Mukhi Rudraksha guarantees good
                health of the respiratory system and thyroid glands and provides
                relief from asthma. When worn by the numbers 108 or 43 as a
                Rudraksha chain it helps to reduce stress. When the 32-beaded
                chain is worn, our enemy shall respect us. This is very
                beneficial for students, scholars, journalists, researchers and
                scientists, writers, etc.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Rudra Kalaagni
              </p>
              <p>
                <strong>Ruling planet: </strong>Jupiter
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Shan Mukhi (Six faced)</h2>
              <p>
                This is ruled by Lord Kartikeya, the son of Lord Shiva with six
                heads, each representing our senses. It bestows the qualities of
                Lord Kartikeya like youthfulness, fearlessness, quick action and
                supreme command.
              </p>
              <p>
                This brings stability in all aspects of the wearer’s life,
                boosts strength, energy, balance, tolerance. It gives relief
                from piles, arthritis, muscular pain, knee pain and bone pain.
                This Rudraksha prevents blood pressure, hysteria, etc. I also
                helps reduce obesity. It acts as a motivator for thrill seekers
                and boosts will power. Shan Mukhi Rudraksha when worn with
                Chatur Mukhi Rudraksha makes Shiv Shakti locket.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Kartikeya
              </p>
              <p>
                <strong>Ruling planet: </strong>Venus, the adversities faced
                during Shukradasha must use this.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Sapta Mukhi (Seven Faced)</h2>
              <p>
                This is ruled by Mahalakshmi, the Goddess of purity and wealth
                and she bestows upon the wearer wealth and love.
              </p>
              <p>
                This removes miseries, bad luck and misfortunes from the life of
                the wearer and blesses him with prosperity, happiness, peace,
                and abundance and harmony in relationships. If this is kept in
                the money vault, it is said to stabilize functioning and
                increase wealth. The wearer will get rid of Saturn (Shani) Dosha
                and will get progress in business and service.
              </p>
              <p>
                This regulates functioning of the digestive system, provides
                relief to diseses related to stomach, adrenal gland, liver and
                pancreas- including diabetes. When used by businessmen, those
                who work in service sector and public administrstors they will
                be blessed with wealth and success.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Mahalakshmi
              </p>
              <p>
                <strong>Ruling planet: </strong>Saturn, early deaths, tiredness,
                paralysis and other ailments related to 'shanidasha' can be
                prevented by wearing this.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Ashta Mukhi (Eight Faced)</h2>
              <p>
                This Rudraksha protects the wearer from the harmful karmas
                befalling him. The benefit of 'Ganapthipooja' is obtained by the
                wearer. For the 'Kethudasha' period, this Rudraksha should be
                worn to get rid of obstacles and gain success. (Incarnation of
                Lord Ganesh). This will increase concentration of intellect,
                develops knowledge and removes all obstacles in proposed plans
                and deeds and will get all-round success - Riddhis and Siddhis.
                Helps for longevity. This is mostly by pundits, Astrologers,
                Professors, etc. It helps in curing early stage of paralysis.
                Takes away all obstacles and brings success in all projects. It
                gives the wearer all kinds of attainments - Riddhies and
                Siddhies even the minds or intentions of the opponents are
                changed for the better.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Ganesha
              </p>
              <p>
                <strong>Ruling planet: </strong>Ketu and Rahu
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Nava Mukhi (Nine Faced)</h2>
              <p>
                This Rudraksha is said to possess nine powers. It has to be worn
                on the right hand. The ones conducting 'Durgapooja' should wear
                this. The blessings provided by it is such that the ones
                suffering from 'Shanidasha', 'Rahurdasha' etc should wear this.
                If 108 beads of 9 faced Rudraksha are worn, It is said to
                provide the benefit of 'Navagraha Pooja' for a peaceful home.
                Besides, it provides the energy, power and subsequent success
                for the wearer. (Incarnation of Lord Bhairava - main deity is
                Goddess Durga Devi). It is very effective in stopping
                miscarriages or abortions, helpful in Santhanprapthi, heart
                diseases, smallpox, skin problems. This is worn on the right
                hand by ladies with perfect devotion, desirous of having
                children. It is much sought-after and rarely available. Gives
                plenty of vigor, force, dynamism, fearlessness. It is Shakti
                Rudraksha. The wearer is blessed with a great deal of energy,
                powers, Dynamism and fearlessness, which are useful to live a
                lifetime of success.
              </p>
              <p>
                <strong>Ruling God: </strong>Goddess Durga
              </p>
              <p>
                <strong>Ruling planet: </strong>Ketu
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Dasha Mukhi (Ten Faced)</h2>
              <p>
                This Rudraksha saves the wearer from all forms of accidents,
                especially the ones connected to the sea. This should be the one
                held out during 'Budhadasha' period. This is more useful in
                spiritual enlightenment, keeps away heart borne diseases,
                releases the stress and tension. Gets dashavathara blessings.
                This bears the influence of ten incarnations and the ten
                directions. It works like a shield on one's body and drives
                evils away.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Vishnu
              </p>
              <p>
                <strong>Ruling planet: </strong>No specific ruling planet for
                this Rudraksha and hence every planetary related 'doshas' can be
                cured using this.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Eka Daska Mukhi (Eleven Faced)</h2>
              <p>
                The wearer of this Rudraksha can make fair judgments and will be
                strong voiced. This aids ones leading a thrill seeking life and
                protects from all sorts of accidents and other detrimental
                events. The ones who meditate should wear this. The wearer of
                this never gets any suicidal thoughts. It also provides good
                health and acts as a protector of the wearer. This should be
                worn on the head, controls many types of diseases and helps in
                curing femine (ladies) diseases and is helpful for santhana
                prapthi. Blessings with wisdom, right judgement powerful
                vocabulary, adventurous life with all-round success. It can
                bless the wearer with wisdom, right judgment, powerful
                vocabulary, adventurous life, fearlessness and success. Above
                all, it also protects from accidental death. It also helps in
                Meditation and removes the problems of yogic practices.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Hanuman
              </p>
              <p>
                <strong>Ruling planet: </strong>No ruling planet for this too,
                and is beneficial for mediation.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Dwadasha Mukhi (Twelve Faced)</h2>
              <p>
                It is good to be worn as an earrings by the women. Also, the
                ones needing to be good administrators, ministers, other
                political activists, businessmen etc should wear this. It is
                also known as Aditya and wearing of this on the head brings
                enlighment to weak minds and new hope and confidence for
                patients with long sufferings and chronic diseases. It controls
                excessive desire for sex and other bad habits. Also is useful to
                cure Jaundice. It is more useful for jyothisha and Vasthu
                Pandits, politician, businessmen and executives cures heart,
                lungs and skin diseases. The wearer gets the quality of the sun
                - to rule and to move continuously with brilliant radiance and
                strength. Good for ministers, politicians, decision makers,
                businessmen and administrators. Takes away worry, suspicion and
                fear. Increases self image and motivation.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Shiva
              </p>
              <p>
                <strong>Ruling planet: </strong>The ruling planet is 'Sun' and
                can be used in the absence of 'Dwi Mukhi' aka two faced
                Rudraksha.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Trayodasha Mukhi (Thirteen Faced)</h2>
              <p>
                This Helps the wearer to have a peaceful life and also boosts
                his spiritual powers. The best wishes of the wearer are
                fulfilled by this Rudraksha. Besides, It helps the wearer the
                fulfillment of wishes and other material gains. It is worn by
                ladies to fulfill their desires, increases their good luck,
                beauty care, cures skin diseases and early stage of cancer. The
                wearer gets all riches and comforts, fulfills all the desires in
                family life, specially desire for Santhana prapthi. It is
                Kamadhenu. It also gives business, promotion, more financial and
                family happiness and honour in the society. Showers in all
                possible comforts of life one can ever desire. It gives riches
                and honor and fulfills all the earthly desires and gives eight
                accomplishments (Siddhis), and the God cupid (Kamadeva) pleases
                with the man who wears it. It is helpful for meditation and
                spiritual and materialistic attainments.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Indra / Kaamdev
              </p>
              <p>
                <strong>Ruling planet: </strong>The ruling planet is 'Venus' and
                this can be used in the absence of 'Shan Mukhi' aka six faced
                Rudraksha
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Chaturdasha Mukhi (Fourteen Faced)</h2>
              <p>
                This is called 'Devamani'. This helps the wearer to access his
                third eye prowess. It should be worn on the front head. The
                wearer also gets inner knowledge and prediction prowess.
                (Incarnation of Lord Parama Shiva). It is all-powerful for
                bestowing good health and proper wealth and is a prathika of
                Lord Hanuman. It helps in curing the facial and paralysis,
                control anger and excess sex. This is worn on forehead (Lalata)
                or on the chest. It is also very difficult to get this
                Rudraksha. It is a most precious divine gem. Wearer foresees the
                future. Its wearer never fails in his decisions. Its wearer gets
                rid of all the calamities, miseries, worries. It protects from
                ghosts, malevolent spirits and dark magic. It offers the wearer
                safety, security and riches and self power.
              </p>
              <p>
                <strong>Ruling God: </strong>Lord Shiva
              </p>
              <p>
                <strong>Ruling planet: </strong>The ruling planet is 'Saturn'
                and can be used in the absence of 'Sapta Mukhi ' aka seven faced
                Rudraksha.
              </p>
            </div>
            <p>
              If you have any queries{" "}
              <Link
                to="/#contact-us"
                title="Get in touch with us"
                className="link_text"
              >
                click here
              </Link>{" "}
              to contact us.
            </p>
          </article>

          <aside className="article-sidebar">
            <AuthorInfo></AuthorInfo>
          </aside>
        </section>
      </section>
      <ServicesList cssClass="blog" />
      <BlogList cssClass="blog" />
    </Layout>
  )
}

export default AdministratorPage
